body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  display: none;
}

.rbc-toolbar > span {
  margin: 5px;
}

.leaflet-control-container .leaflet-routing-container-hide {
  display: none;
}

.leaflet-popup{
  width:auto!important;
}

.leaflet-popup-close-button{
  margin-top:5%!important;
  margin-right:5%!important;
}

@keyframes pulse {
	0% {
		transform: scale(1);
    filter: drop-shadow(0 0 0.3rem rgba(255, 0, 0, 0.9));
	}

	70% {
		transform: scale(1.2);
    filter: drop-shadow(0 0 0.5rem rgba(255, 0, 0, 0.9));
	}

	100% {
		transform: scale(1);
    filter: drop-shadow(0 0 0.3rem rgba(255, 0, 0, 0.9));
	}
}